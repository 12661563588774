import { FlaskConical } from "lucide-react";

import { ButtonLink } from "@/components/ui/button";

export function NotFound() {
  return (
    <div className="flex h-screen flex-col items-center justify-center">
      <div className="flex h-20 w-20 items-center justify-center rounded-full bg-gold-100">
        <FlaskConical strokeWidth={1} size={32} />
      </div>
      <div className="flex flex-col items-center justify-center gap-6 px-4 text-center md:max-w-[698px]">
        <h2 className="mt-4">
          Looks Like You’ve Discovered Uncharted Territory!
        </h2>
        <div className="p2">
          In the quest for knowledge, sometimes we find ourselves in unknown
          places. The page you’re looking for might have been moved, renamed, or
          is temporarily unavailable.
        </div>
        <div>
          <ButtonLink href="/home" className="md:w-[553px]">
            Return Home
          </ButtonLink>
        </div>
      </div>
    </div>
  );
}
