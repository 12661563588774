import { NotFound } from "@/components/common/notfound";
import MainLayout from "@/components/main-layout";

export default function Custom404() {
  return (
    <MainLayout isPublic>
      <NotFound />
    </MainLayout>
  );
}
